import './App.css';
// import React, { useEffect, useState } from "react";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';


const Weapons = require("./Unlocks.json")
const Info = require("./Info.json")
const genInfo = require("./generalInfo.json")
const Vehicles = require("./Vehicles.json")
const Ranks = require("./ranksXP.json")
const VehicleKills = [];
const topVehiclesByKills = {};
var keysSorted;
const finInfo = {};
var vehicleKillArray = Vehicles.data.mainVehicleStats.slice(0);
var rankArray = Ranks.slice(0);
configureVehicleObj();
configureWeaponObj();
configureGenInfoObj();
configureRankObj();

ChartJS.register(ArcElement, Tooltip, Legend);

function Team() {
  return (
    <div className='teamContainer'>
      <div className='teamDiagram'>
        <div className='diagram'>
        <Chart/>        

        </div>
        <div className='teamDiagramInfo'>
          <p>Wins</p>
          <p>{finInfo.winRate}%</p>
        </div>
      </div>
      <div className='teamSquadScore'>
        <p>Squad Score</p>
        <p>{finInfo.overviewStats.sc_squad}</p>
      </div>
    </div>
  )
}

console.log(finInfo.winRate)

export const data = {
  datasets: [
    {
      label: '# of Votes',
      data: [finInfo.winRate, finInfo.looseRate],
      backgroundColor: [
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 99, 132, 0.2)'
      ],
      borderColor: [
        'rgba(54, 162, 235, 1)',
        'rgba(255, 99, 132, 1)'
      ],
      borderWidth: 1,
    },
  ],
};




function Chart() {
  return(
    <Doughnut data={data} width={100} height={100}/>
  )
}



function configureGenInfoObj() {
  var overviewStats = {};
  var WeaponsData = {};
  finInfo.playerName = Info.data.myPersonas[0].personaName;
  finInfo.playerID = genInfo.data.personaId;
  finInfo.currentRankNeeded = genInfo.data.currentRankNeeded;
  finInfo.nextRankNeeded = genInfo.data.nextRankNeeded;
  finInfo.gameProgress = genInfo.data.gameProgress;
  for (const prop in genInfo.data.overviewStats) {
    if (genInfo.data.overviewStats[prop]) {
      overviewStats[prop] = genInfo.data.overviewStats[prop]
    }
  }
  finInfo.overviewStats = overviewStats;
  finInfo.topWeapons = genInfo.data.topWeapons;
  finInfo.topVehicles = genInfo.data.topVehicles;
  WeaponsData.WeaponsByCat = Weapons.data.weaponsByCategory;
  WeaponsData.Stats = Weapons.data.mainWeaponStats;
  WeaponsData.StatsMeta = Weapons.data.mainWeaponStatsMetadata;
  finInfo.WeaponData = WeaponsData;
}

function configureRankObj() {
  rankArray.sort(function(a,b) {
    return b - a
  });
  var currentGesXP = 0;
  for (let i = 0; i < rankArray.length; i++) {
    const element = rankArray[i];
    
    currentGesXP += element.XP;
    element.gesXP = currentGesXP;
    if (finInfo.overviewStats.score - element.gesXP > 0) {
      finInfo.currentRank = element.rankName;
      finInfo.currentRankGesXP = element.gesXP;
      finInfo.currentRankProgress = finInfo.overviewStats.rankScore - finInfo.currentRankGesXP;
      finInfo.curentIndex = i;

    }
  }
  finInfo.currentRankXP = rankArray[finInfo.curentIndex + 1].gesXP - rankArray[finInfo.curentIndex].gesXP;
  finInfo.progressPercent = (finInfo.currentRankProgress / finInfo.currentRankXP) * 100;
  finInfo.winRate = Math.ceil((finInfo.overviewStats.numWins / (finInfo.overviewStats.numLosses + finInfo.overviewStats.numWins)) * 100)
  finInfo.looseRate = 100 - finInfo.winRate;
}


function configureWeaponObj() {
  delete Weapons.data.compareWeaponStats
}

function configureVehicleObj() {
  vehicleKillArray.sort(function(a,b) {
    return b.kills - a.kills
  });
}

console.log("INFO", Info)
console.log("Weapons", Weapons)
console.log("Ranks", Ranks)
console.log("genInfo", genInfo)
console.log("genInfo FINAL", finInfo)
console.log("Vehicles", Vehicles)
console.log("Sorted", vehicleKillArray)
console.log("SortedRanks", rankArray)


function App() {
  return (
    <div className="App">
      <Header/>
      <Submenu/>
      <div className="baseContainer">
        <div className='homeProfileDisplay'>
          <ProfileDisplay bigProfile={true} />
        </div>
        <div className='statsContainer'>
          <Row rowNr={1}/>
          <Row rowNr={2}/>
          <Row rowNr={3}/>
          <Row rowNr={4}/>
        </div>
      </div>
    </div>
  );
}

function Row(props) {
  switch (props.rowNr) {
    case 1:
      return (
        <div className="homeRow" >
          <SmallHomeElement type={Rank} header="Rank"/>
          <SmallHomeElement type={Skill} header="Skill"/>
          <SmallHomeElement type={Team} header="Team"/>
        </div>
      )
  case 2:
    return (
      <div className="homeRow" >
        <SmallHomeElement type={ServiceStars} header="Service Stars"/>
      </div>
    )
  case 3:
    return (
      <div className="homeRow" >
          <SmallHomeElement type={TopWeapons} header="Top Weapons"/>
          <SmallHomeElement type={TopVehicles} header="Top Vehicles"/>
          <SmallHomeElement type={TopGameModes} header="Top Game Modes"/>
      </div>  
    )
  case 4:
    return (
      <div className="homeRow" >
        <SmallHomeElement type={CompletionLevel} addClass="completionLevel" header="Completion Level"/>
      </div>  
    )      
  }
}

function ServiceStars() {
  return (
    <div className='serviceStarContainer'>
      <ServiceStarEl serviceStarClass="medic" neededXP={155000} currentXP={finInfo.overviewStats.serviceStarsProgress[1]}/>
      <ServiceStarEl serviceStarClass="engineer" neededXP={131000} currentXP={finInfo.overviewStats.serviceStarsProgress[2]}/>
      <ServiceStarEl serviceStarClass="support" neededXP={134000} currentXP={finInfo.overviewStats.serviceStarsProgress[32]}/>
      <ServiceStarEl serviceStarClass="recon" neededXP={104000} currentXP={finInfo.overviewStats.serviceStarsProgress[8]}/>
      <ServiceStarEl serviceStarClass="commander" neededXP={20000} currentXP={finInfo.overviewStats.serviceStarsProgress[2048]}/>
    </div>
  )
}

function ServiceStarEl(props) {
  var roundedCurrentXP = Math.floor(props.currentXP) / 100;
  var currentXP = props.neededXP * roundedCurrentXP
  var info = currentXP + " | " + props.neededXP;
  return (
    <div className={`serviceStar ${props.serviceStarClass}`}>
        <div className='serviceStarIcon '></div>
        <p className='serviceStarHeader'>{props.serviceStarClass}</p>
        <div className='serviceStarProgress'>
          <div style={{width: props.currentXP + "%"}} className='serviceStarProgressInner'></div>
        </div>
        <p className='serviceStarInfo'>{info}</p>
    </div>
  )
}



function CompletionLevel(params) {
  return (
    <div className='comletionLevelContainer'>
      <CompletionLevelElement name="Campaign" elements={finInfo.gameProgress[0].max} progress={finInfo.gameProgress[0].current}/>
      <CompletionLevelElement name="Assignments" elements={finInfo.gameProgress[1].max} progress={finInfo.gameProgress[1].current}/>
      <CompletionLevelElement name="Medals" elements={finInfo.gameProgress[2].max} progress={finInfo.gameProgress[2].current}/>
      <CompletionLevelElement name="Ribbons" elements={finInfo.gameProgress[3].max} progress={finInfo.gameProgress[3].current}/>
      <CompletionLevelElement name="Weapons" elements={finInfo.gameProgress[4].max} progress={finInfo.gameProgress[4].current}/>
      <CompletionLevelElement name="Vehicle Unlocks" elements={finInfo.gameProgress[5].max} progress={finInfo.gameProgress[5].current}/>
      <CompletionLevelElement name="Kits" elements={finInfo.gameProgress[6].max} progress={finInfo.gameProgress[6].current}/>
      <CompletionLevelElement name="Dog Tags" elements={finInfo.gameProgress[7].max} progress={finInfo.gameProgress[7].current}/>
    </div>
  )
}



function CompletionLevelElement(props) {
  var progressPercent = Math.floor(props.progress / props.elements * 100)
  if (props.elements == props.progress) {
    var completed = "completed"
  }
  return (
    <div className='completionLevelElementContainer'>
      <p className='completionLevelName'>{props.name}</p>
      <div className='progressBar'>
        <div style={{width: progressPercent + "%"}} className={`completionProgressBarProgress ${ props.name }`}>

        </div>
      </div>
      <p className={`progressBarProgressNumbers ${ completed }`}>
        {props.progress} | {props.elements}
      </p>
    </div>
  )
}


// TODO: Info einfügen

function TopWeapons(props) {
  return (
    <div className='topWeaponsContainer'>
      <TopElement name={finInfo.WeaponData.Stats[0].slug} kills={2074} isBig={true}/>
      <TopElement name={finInfo.WeaponData.Stats[1].slug} kills={1586}/>
      <TopElement name={finInfo.WeaponData.Stats[2].slug} kills={971}/>
      <TopElement name={finInfo.WeaponData.Stats[3].slug} kills={839}/>
    </div>
  )
}

// TODO: Info einfügen

function TopVehicles(props) {
  return (
    <div className='topVehiclesContainer'>
      <TopElement name={vehicleKillArray[0].slug} kills={vehicleKillArray[0].kills} isBig={true}/>
      <TopElement name={vehicleKillArray[1].slug} kills={vehicleKillArray[1].kills}/>
      <TopElement name={vehicleKillArray[2].slug} kills={vehicleKillArray[2].kills}/>
      <TopElement name={vehicleKillArray[3].slug} kills={vehicleKillArray[3].kills}/>
    </div>
  )
}

function TopGameModes(props) {
  return (
    <div className='topGameModesContainer'>
      <GameModeElement name="Conquest" stars={finInfo.overviewStats.serviceStarsGameModes[0].serviceStars} progress={finInfo.overviewStats.serviceStarsGameModes[0].serviceStarsProgress}/>
      <GameModeElement name="Rush" stars={finInfo.overviewStats.serviceStarsGameModes[1].serviceStars} progress={finInfo.overviewStats.serviceStarsGameModes[1].serviceStarsProgress}/>
      <GameModeElement name="Deathmatch" stars={finInfo.overviewStats.serviceStarsGameModes[2].serviceStars} progress={finInfo.overviewStats.serviceStarsGameModes[2].serviceStarsProgress}/>
      <GameModeElement name="Obliteration" stars={finInfo.overviewStats.serviceStarsGameModes[3].serviceStars} progress={finInfo.overviewStats.serviceStarsGameModes[3].serviceStarsProgress}/>
    </div>
  )
}

function GameModeElement(props) {
  return (
    <div className='gameModeElement'>
      <p>{props.name}</p>
      <div className='gameModeProgressbar'>
        <div style={{width:props.progress+"%"}} className={`gameModeProgress ${ props.name }`}></div>
        <span className='starCount'>{props.stars}</span>
      </div>
    </div>
  )
}



function TopElement(props) {
  var containerClass = "smallContainer";
  var displayName = props.name.toUpperCase().replace("-"," ");
  var imageClass = props.name.replace("-", " ").replace(" ","").toLowerCase();
  return (
    <div className={`topElementContainer ${ containerClass }`}>
      <div className={`weaponImage ${ imageClass }`}></div>
      <div className="topElementInfoContainer">
        <p className='weaponName'>{displayName}</p>
        <p className='weaponKills'>{props.kills} Kills</p>
      </div>
    </div>
  )
}



// TODO: Info einfügen

function Rank() {
  return (
    <div className='rankOuterContainer'>
      <div className='rankEl'>
      </div>
      <div className='progressContainer'>

      <div className='progressBar'>
        <div style={{width: finInfo.progressPercent + "%"}} className='progressBarProgress'>

        </div>
      </div>
      <div className='progressInfo'>
        <p>{finInfo.currentRank}</p>
        <p className='progressXP'>{finInfo.currentRankProgress} | {finInfo.currentRankXP}</p>
      </div>
      </div>
    </div>
  )
}

function SkillElement(props) {
  return(
    <div className={`skillElementContainer ${ props.containerClass }`}>
      <div className={`skillElementInnerContainer ${ props.innerContainerClass }`}>
        <p>{props.header}</p>
        <p>{props.info}</p>
      </div>
    </div>
  )
}



// TODO: Info mit Obj Info tauschen

function Skill() {
  var skillProgress = (finInfo.overviewStats.skill / 1000) * 100;
  var totalPlayTimeGes = finInfo.overviewStats.timePlayed / 3600;
  var totalPlayTimeHours = Math.floor(totalPlayTimeGes);
  var totalPlayTimeMins = Math.floor(totalPlayTimeGes % 1 * 60);
  return (
    	<div className='skillOuterContainer'>
        <div className='skillGeneral'>
          <p>{finInfo.overviewStats.skill}</p>
          <div className='skillProgressBar'>
            <div style={{width: skillProgress + "%"}} className='skillProgress'></div>
          </div>
        </div>
        <SkillElement header="K|D" info={finInfo.overviewStats.kdRatio} containerClass="skillKD"/>
        <SkillElement header="SPM" info={finInfo.overviewStats.scorePerMinute} containerClass="skillSPM"/>
        <SkillElement header="KPM" info={finInfo.overviewStats.killsPerMinute} containerClass="skillKPM"/>
        <SkillElement header="Kills" info={finInfo.overviewStats.kills} containerClass="skillKills"/>
        <SkillElement header="Score" info={finInfo.overviewStats.score} containerClass="skillScore"/>
        <SkillElement header="Time" info={totalPlayTimeHours + "h " + totalPlayTimeMins + "m"}  containerClass="skillTime"/>
      </div>
  )
}

// TODO: Info einfügen - SVG anschauen

function Submenu() {
  return (
    <div className="subOuterContainer">
      <div className="subInnerContainer">
        <SubmenuItem title="Soldier" isSoldier={true} />
        <SubmenuItem title="Weapons"/>
        <SubmenuItem title="Vehicles"/>
        <SubmenuItem title="Awards"/>
      </div>
    </div>

  )
}

function SubmenuItem(props) {
  if (props.isSoldier) {
    return (
      <div className="subItemContainer soldier">
        <div className="soldierImg"></div>
        <div className="rank"></div>
        <div className="platform"></div>
        <span>{props.title}</span>
      </div>
    )
  } else {
    return (
      <div className="subItemContainer">
        <span>{props.title}</span>
      </div>
    )
  }
}

function Header() {
  return (
    <div className="headerContainer">
      <div className="headerInnerContainer">
        <div className="headerLeftContainer">
         <a href="#" className="logo"></a>
        </div>
        <div className="headerRightContainer">
          <ProfileDisplay/>
        </div>
      </div>
    </div>
  )
}

// TODO: Info einfügen

function ProfileDisplay(props) {
  if (props.bigProfile) {
    return(
    <a className="profileBig">
      <span className="profileImageBig"></span>
      <div className='profileInfo'>
        <span className="profileNameBig">{finInfo.playerName}</span>
        <div className='profileGamesPlatform'>
          <span className='profileGames'>BF4 | BF1 | BFV</span>
          <span className='profilePlatform'>PC</span>
        </div>
      </div>
    </a>
    )
  } else {
    return (
      <a className="profile">
        <span className="profileName">{finInfo.playerName}</span>
        <span className="profileImage"></span>    
      </a>
    )
  }
}

function SmallHomeElement(props){
  let Type = props.type;
  return (
    <div className="smallHomeOuterContainer">
      <div className='smallHomeHeaderContainer'>
        <span className="smallHomeHeader">{props.header}</span>
      </div>
      <div className={`infoContainer ${ props.addClass }`}>
        <Type/>
      </div>
    </div>
  )
}

export default App;
